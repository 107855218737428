<template>
  <div>
    <div class="header">
      <h2>Recadreur d'image iD3i</h2>
    </div>
    <hr />
    <ul>
      <li>Importer une image</li>
      <li>
        Utiliser les boutons ou la souris pour vous déplacer (ou zoomer) dans la
        zone de recadrage
      </li>
      <li>
        Utiliser le bouton Découper une fois le résultat en direct satisfaisant
      </li>
      <li>Télécharger la nouvelle image et renommer la</li>
    </ul>
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />

    <div class="content">
      <section class="cropper-area">
        <div class="img-cropper">
          <vue-cropper
            ref="cropper"
            :aspect-ratio="2 / 1"
            :src="imgSrc"
            preview=".preview"
          />
        </div>
        <div class="actions">
          <a href="#" role="button" @click.prevent="zoom(0.2)"> Zoomer </a>
          <a href="#" role="button" @click.prevent="zoom(-0.2)"> Dézoomer </a>
          <a href="#" role="button" @click.prevent="move(-10, 0)"> Gauche </a>
          <a href="#" role="button" @click.prevent="move(10, 0)"> Droite </a>
          <a href="#" role="button" @click.prevent="move(0, -10)"> Haut </a>
          <a href="#" role="button" @click.prevent="move(0, 10)"> Bas </a>
          <a href="#" role="button" @click.prevent="rotate(90)"> +90deg </a>
          <a href="#" role="button" @click.prevent="rotate(-90)"> -90deg </a>
          <a ref="flipX" href="#" role="button" @click.prevent="flipX">
            Retourner vers le droite
          </a>
          <a ref="flipY" href="#" role="button" @click.prevent="flipY">
            Retourner vers le haut
          </a>
          <a
            href="#"
            role="button"
            class="important"
            @click.prevent="cropImage"
          >
            Découper
          </a>
          <a href="#" role="button" @click.prevent="reset"> Reinitialiser </a>
          <!-- <a href="#" role="button" @click.prevent="getData"> Get Data </a>
          <a href="#" role="button" @click.prevent="setData"> Set Data </a> -->
          <!-- <a href="#" role="button" @click.prevent="getCropBoxData">
            Get CropBox Data
          </a>
          <a href="#" role="button" @click.prevent="setCropBoxData">
            Set CropBox Data
          </a> -->
          <a
            href="#"
            role="button"
            class="important"
            @click.prevent="showFileChooser"
          >
            Importer une image
          </a>
        </div>
      </section>
      <section class="preview-area">
        <p>Résultat en direct</p>
        <div class="preview" />
        <p>Image définitive</p>
        <div class="cropped-image">
          <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
          <div v-else class="crop-placeholder" />
        </div>
        <a href="#" role="button" class="important" @click="download"
          >Télécharger
        </a>
      </section>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import axios from "axios";
export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      imgSrc: "/assets/images/logoid3ivectorielPNG.png",
      cropImg: "",
      data: null,
    };
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    download() {
      axios({
        method: "get",
        url: this.cropImg,
        responseType: "blob",
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log("error occured"));
    },
    forceFileDownload(response) {
      var headers = response.headers;
      var dataType = response.data.type.split("/");
      var extension = dataType[0] === "image" ? dataType[1] : "png";
      var blob = new Blob([response.data], { type: headers["content-type"] });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `id3i-recadre.${extension}`;
      link.click();
      link.remove();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}
input[type="file"] {
  display: none;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}
.header h2 {
  margin: 0;
}
.header a {
  text-decoration: none;
  color: black;
}
.content {
  display: flex;
  justify-content: space-between;
}
.cropper-area {
  width: 614px;
}
.actions {
  margin-top: 1rem;
}
a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  margin-right: 10px;
  margin-bottom: 1rem;
}

.important {
  background: #ea691f;
  font-weight: 700;
}
textarea {
  width: 100%;
  height: 100px;
}
.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}
.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.cropped-image img {
  max-width: 100%;
}
</style>